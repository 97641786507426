<template>
  <b-card>
    <div class="flex items-start">
      <b-button
        variant="primary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$emit('back')"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <div class="text-black mb-2 text-2xl font-semibold">
        Detail Pengajuan Packing
      </div>
    </div>
    <div class="border rounded-lg p-6 space-y-6">
      <b-row
        align-v="center"
        class="text-black"
      >
        <b-col cols="3">
          Status
        </b-col>
        <b-col cols="4">
          <b-badge
            :variant="detailPacking.status === 'Dipacking' ? 'light-info' : 'light-warning'"
          >
            {{ detailPacking.status }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        class="text-black"
      >
        <b-col cols="3">
          Label Pengiriman
        </b-col>
        <b-col cols="8">
          <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-text.svg"
            width="20"
            alt="label"
            class="inline mr-[5px] self-center"
          />
          <b-link
            class="underline !text-primary"
            :href="detailPacking.label_url"
            target="_blank"
          >
            {{ detailPacking.label_name }}
          </b-link>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        class="text-black"
      >
        <b-col cols="3">
          Jumlah Orderan pada Resi
        </b-col>
        <b-col cols="4">
          {{ detailPacking.grand_total_order }}
        </b-col>
      </b-row>
    </div>
    <div class="mt-6 space-y-6">
      <div class="text-black">
        Rangkuman Order
      </div>
      <b-table
        :items="detailPacking.order_summary"
        :fields="fieldsDetailPacking"
      />
      <div class="text-black space-y-2">
        <b-row align-h="end">
          <b-col cols="2">
            Total Order
          </b-col>
          <b-col cols="0">
            :
          </b-col>
          <b-col cols="2">
            <span>
              {{ detailPacking.grand_total_order }}
            </span>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="2">
            Total Biaya
          </b-col>
          <b-col cols="0">
            :
          </b-col>
          <b-col cols="2">
            <span>
              {{ detailPacking.grand_fulfillment_fee | rupiah }}
            </span>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import filters from '@/libs/filters'

export default {
  filters: { rupiah: filters.rupiah },
  props: {
    detailId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      detailPacking: {},

      refId: this.$route.query.ref_id,

      fieldsDetailPacking: [
        { key: 'order_number', label: 'Jenis Order', thClass: '!capitalize' },
        { key: 'product_name', label: 'Produk', thClass: '!capitalize' },
        { key: 'product_variant_name', label: 'Variasi', thClass: '!capitalize' },
        { key: 'total_item', label: 'Jumlah Item', thClass: '!capitalize' },
        { key: 'total_order', label: 'Jumlah Order', thClass: '!capitalize' },
      ],
    }
  },
  created() {
    this.fetchDetailPacking()
  },
  methods: {
    async fetchDetailPacking() {
      await newAxiosIns.get(`/kompack/api/v1/outbounds/${this.refId ? this.refId : this.detailId}`)
        .then(res => {
          const { data } = res.data

          this.detailPacking = {
            ...data,
            status: data.status === 'confirmed' ? 'Dipacking' : 'Diajukan',
          }

          if (data.status === 'confirmed') {
            this.fieldsDetailPacking.push({
              key: 'total_order_approved',
              label: 'Disetujui Mitra',
              thClass: '!capitalize',
            })
          }

          const result = []
          this.detailPacking.order_summary.forEach((order, index) => {
            order.products.forEach(product => {
              if (product.is_bundling && product.product_bundles.length > 0) {
                product.product_bundles.forEach(bundle => {
                  result.push({
                    order_number: index + 1,
                    total_order: order.total_order,
                    total_order_approved: order.total_order_approved,
                    product_id: bundle.product_id,
                    product_variant_id: bundle.product_variant_id,
                    product_name: product.product_name,
                    product_variant_name: bundle.product_variant_name || bundle.product_name,
                    total_item: product.total_item,
                    is_bundling: product.is_bundling,
                  })
                })
              } else {
                result.push({
                  order_number: index + 1,
                  total_order: order.total_order,
                  total_order_approved: order.total_order_approved,
                  product_id: product.product_id,
                  product_variant_id: product.product_variant_id,
                  product_name: product.product_name,
                  product_variant_name: product.product_variant_name === '' ? '-' : product.product_variant_name,
                  total_item: product.total_item,
                  is_bundling: product.is_bundling,
                })
              }
            })
          })
          this.detailPacking.order_summary = result
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}
</script>
